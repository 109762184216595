<template>
  <div>
    <b-card title="Ekle">
      <b-form-group
        label="Başlık"
        label-for="title"
      >
        <b-form-input
          id="title"
          v-model="event.title"
          placeholder="Başlık"
        />
      </b-form-group>
      <b-form-group
        label="Etkinlik Tarihi"
        label-for="edate"
      >
        <b-form-datepicker
          id="edate"
          v-model="event.edate"
          v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
      </b-form-group>
      <b-form-group
        label="Metin"
        label-for="content"
      >
        <b-form-textarea
          id="content"
          v-model="event.summary"
          placeholder="Metin"
        />
      </b-form-group>
      <b-form-group
        label="Detay"
        label-for="content"
      >
        <b-form-textarea
          id="content"
          v-model="event.content"
          placeholder="Metin"
        />
      </b-form-group>
      <hr>
      <b-form-group
        label="Sembol"
      >
        <b-row>
          <b-col
            v-for="(icon,key) in icons"
            :key="key"
            cols="auto"
          >
            <b-form-radio
              v-model="event.icon"
              name="icons"
              :value="icon"
              stacked
            >
              <FeatherIcon
                class="text-primary"
                :icon="icon"
                size="24"
              />
            </b-form-radio>
          </b-col>
        </b-row>
      </b-form-group>
    </b-card>
    <b-button
      variant="danger"
      :disabled="event.submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormTextarea,
  BFormGroup,
  BFormDatepicker,
  BButton, BFormRadio, BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'Add',
  components: {
    BCard,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormDatepicker,
    BButton,
    BFormRadio,
    BRow,
    BCol,
  },
  data() {
    return {
      icons: [
        'AwardIcon',
        'InfoIcon',
        'UserIcon',
        'BoxIcon',
      ],
      submitStatus: false,
    }
  },
  computed: {
    event() {
      return this.$store.getters['events/getEvent']
    },
    saveStatus() {
      return this.$store.getters['events/getEventSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.getEvent()
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.notice.submitStatus = false
    },
  },
  beforeCreate() {
    this.$store.dispatch('events/eventReset')
  },
  created() {
    this.getEvent()
  },
  methods: {
    getEvent() {
      this.$store.dispatch('events/eventView', this.$route.params.id)
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.event.submitStatus = true
      this.$store.dispatch('events/eventSave', this.event)
    },
  },
}
</script>
